<template>
  <v-container fluid>
    <div class="main">

      <Statistiques/>
      <v-row v-if="isLoading">
        <v-col cols="12">
          <v-card class="rounded-lg" flat>
            <v-skeleton-loader type="table"></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12">
          <v-card class="rounded-lg" flat>
            <v-card-text>
              <div class="d-flex justify-space-between mb-2">
                <div>
                  <!-- <v-btn :disabled="selectedCards.length == 0" color="primary" rounded depressed
                                        @click="sendRefill" style="color:white">
                                        <v-icon left>mdi-send </v-icon>
                                        Recharge
                                    </v-btn> -->
                </div>
                <div class="d-flex justify-space-between">
                  <v-text-field
                      v-model="filter.keyword"
                      rounded
                      filled
                      dense
                      style="width: 300px"
                      append-icon="mdi-magnify"
                      single-line
                      hide-details
                      clearable
                      placeholder="Rechercher..."
                      v-on:keyup.enter="fetchData"
                  />

                  <!-- <FilterDialog @filter="[filterBy($event)]" />
                                    <create-dialog @refresh="[refresh()]" /> -->
                  <addCardDialog @refresh="[refresh()]"/>

                  <v-btn color="primary" depressed rounded class="ml-2" @click="$refs.importCardDialog.open()">
                    <v-icon left>mdi-import</v-icon>
                    Importer
                  </v-btn>

                </div>
              </div>
              <v-divider/>
              <div v-if="isLoading">
                <v-skeleton-loader type="table"></v-skeleton-loader>
              </div>
              <div v-else>
                <div v-if="cards.data.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th></th>
                        <th class="font-weight-bold">ID</th>
                        <th class="font-weight-bold">Montant</th>
                        <th class="font-weight-bold">Type</th>
                        <th class="font-weight-bold">Status</th>
                        <th class="font-weight-bold text-center">Avec API ?</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="item in cards.data" :key="item.id">
                        <td>
                          <v-checkbox
                              v-model="selectedCards"
                              label=""
                              :value="item.id"
                          ></v-checkbox>
                        </td>

                        <td class="font-weight-bold">
                          {{ item.id }}
                        </td>
                        <td class="font-weight-bold">
                          {{ CurrencyFormatting(item.amount) }} DZD
                        </td>
                        <td class="font-weight-bold">
                          {{ item.type }}
                        </td>

                        <td>
                          <v-chip
                              :color="setStatusColor(item.used)"
                              label
                              small
                              dark
                          >
                            {{ setStatus(item.used) }}
                          </v-chip>
                        </td>

                        <td class="font-weight-bold text-center">
                          {{ item.martship_card_id ? "OUI" : "NON" }}
                        </td>
                        <td>
                          <v-btn @click="openMartchipCard(item.martship_card)" icon color="blue"

                                 v-if="item.martship_card">
                            <v-icon>mdi-page-next-outline</v-icon>
                          </v-btn>
                        </td>

                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div class="d-flex mt-4">
                    <span class="mt-2 mr-2">Lignes par page :</span>
                    <v-select
                        filled
                        v-model="filter.size"
                        style="width: 20px"
                        dense
                        :items="[10, 20, 30, 40, 50]"
                        @change="onPageChange"
                    ></v-select>
                    <v-spacer/>
                    <v-pagination
                        total-visible="6"
                        v-if="cards.total > filter.size"
                        circle
                        v-model="filter.page"
                        :length="Math.ceil(cards.total / filter.size)"
                        @input="onPageChange"
                    ></v-pagination>
                  </div>
                </div>
                <div v-else>
                  <div class="text-center">
                    <v-avatar tile size="200">
                      <v-img :src="require('@/assets/database.svg')"></v-img>
                    </v-avatar>
                    <h3 class="text--primary d-block mb-2">OOPS !</h3>
                    <p>Aucun enregistrement correspondant trouvé.</p>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <MartchipCard ref="martchipCard"/>
    <ImportCardDialog ref="importCardDialog" @refresh="fetchData"/>

  </v-container>
</template>

<script>
import Statistiques from './components/Statistiques.vue';
import addCardDialog from "./components/addCardDialog.vue";
import MartchipCard from './components/MartchipCard.vue';
import ImportCardDialog from "@/views/cards/components/ImportCardDialog";

export default {
  components: {ImportCardDialog, addCardDialog, Statistiques, MartchipCard},
  data() {
    return {
      filter: {
        endDate: "",
        keyword: "",
        size: 10,
        page: 1,
      },
      pagination: {
        current: 1,
        total: 0,
      },
      delete_id: null,
      selectedCards: [],
    };
  },
  methods: {
    fetchData() {
      this.$store.dispatch("fetchListCards", {
        filter: this.filter,
      });

      this.$store.dispatch("fetchCards", {
        filter: {},
      });
    },
    onPageChange() {
      this.fetchData();
    },
    setStatusColor(status) {
      status = parseInt(status);

      switch (status) {
        case 0:
          return "warning ";
        case 1:
          return "success ";
      }
    },
    setStatus(status) {
      status = parseInt(status);

      switch (status) {
        case 0:
          return "Non Utilisé";
        case 1:
          return "Utilisé";
      }
    },

    openMartchipCard(item) {
      this.$refs.martchipCard.open(item);
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters.getLoading;
    },
    cards() {
      return this.$store.getters.getListCards;
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>