<template>
  <v-dialog max-width="500" scrollable v-model="dialog" persistent>
    <template v-slot:activator="{ on: menu }">
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            color="primary"
            elevation="0"
            depressed
            rounded
            v-on="{ ...tooltip, ...menu }"
            class="ml-2"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </template>
        <span>Inserer une carte</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-progress-linear v-if="isLoading" color="primary" indeterminate reverse>
      </v-progress-linear>

      <v-card-title>
        <v-icon left>mdi-tune</v-icon>
        Insertion d'une Carte
        <v-spacer />
        <v-btn
          small
          outlined
          text
          class="mr-2 ml-2 no-anim"
          @click="clearForm"
          >Effacer</v-btn
        >

        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-img
              max-height="80"
              max-width="80"
              :src="require('@/assets/voucher.png')"
            ></v-img>
          </v-col>

          <v-col cols="12" v-if="error">
            <v-alert border="left" color="error" dark>
              {{ error }}
              <ul v-if="errors">
                <li
                  v-for="(value, key, index) in errors"
                  :key="`${key}-${index}`"
                >
                  {{ value[0] }}
                </li>
              </ul>
            </v-alert>
          </v-col>
          <v-col cols="12">
            <v-select
              prepend-icon="mdi-filter-outline"
              chips
              deletable-chips
              small-chips
              dense
              clearable
              :items="models"
              v-model="form.recharge_card_id"
              :item-text="'name'"
              :item-value="'id'"
              label="Model..."
              hide-details
              @change="modelChanged"
            >
              <template v-slot:item="slotProps">
                <div style="width: 50px">
                  <img
                    :src="getImageLink(slotProps.item.image)"
                    max-height="25"
                    max-width="25"
                    width="25"
                    height="25"
                    class="p-2"
                  />
                </div>

                {{ slotProps.item.name }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.card_number"
              prepend-icon="mdi-ticket-percent-outline"
              type="text"
              dense
              clearable
              label="Code.."
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              disabled
              filled
              v-model="form.type"
              dense
              :items="cardTypes"
              label="Type du Carte "
              item-value="id"
              item-text="name"
              @input="
                errors && errors.type
                  ? (errors.type = '')
                  : false
              "
              :error-messages="errors.type"
            >
            </v-select>
          </v-col>
          

          <v-col cols="12">
            <v-text-field
              v-model="form.amount"
              disabled
              prepend-icon="mdi-currency-usd "
              type="number"
              dense
              clearable
              label="Montant.."
              hide-details
            ></v-text-field>
          </v-col>

          <!-- <v-col cols="12">
            <v-text-field
              v-model="form.expiration_date"
              prepend-icon="mdi-calendar"
              append-icon="mdi-calendar"
              type="date"
              dense
              clearable
              label="Date d’expiration.."
              hide-details
            >
            </v-text-field>
          </v-col> -->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :disabled="isDisabled"
          depressed
          block
          @click="handleStore"
        >
          Sauvegarder
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      dialog: false,
      form: {
        card_number: "",
        amount: 0,
        recharge_card_id: null,
        type: null,
        expiration_date: null,
      },
      isLoading: false,
      error: "",
      errors: [],

      cardTypes: [
        {
          id: "Idoom",
          name: "Idoom",
        },
        {
          id: "4G",
          name: "4G",
        },
      ],
    };
  },
  methods: {
    modelChanged() {
      var item = this.models.find(
        (x) => x.id === this.form.recharge_card_id
      );
      this.form.amount = item.amount;
      this.form.type = item.type;
    },

    handleStore() {
      //this.dialog = false
      //this.$emit('filter', this.filter)
      this.isLoading = true;
      this.errors = [];
      this.err = "";
      HTTP.post("/cards/list/create", this.form, { params: {} })
        .then((res) => {
          console.log(res.data);

          this.isLoading = false;
          this.dialog = false;

          this.$store.dispatch("fetchListCards", {
                filter: {

                },
            });
        })
        .catch((err) => {
          this.isLoading = false;

          this.error = err.response.data.message;
          this.errors = err.response.data.errors;
        });
    },
    clearForm() {
      this.form = {};
    },
    close() {
      this.dialog = false;
    },
    getImageLink(file) {
      return process.env.VUE_APP_FILE_URL + file;
    },
  },
  computed: {
    isDisabled() {
      return (
        Object.values(this.form).every(
          (x) => x === null || x === "" || x.length === 0
        ) || this.isLoading
      );
    },
    suppliers() {
      return this.$store.getters.getSuppliers;
    },
    models() {
      console.log(this.$store.getters.getCards);
      return this.$store.getters.getCards;
    },
  },
};
</script>
  
  <style scoped>
</style>
  
  <style scoped>
.no-anim:hover {
  transform: none;
}
</style>