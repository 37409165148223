<template>
  <div>
    <v-dialog v-model="dialog" width="600">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title>
          Importer des cartes
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-8">

          <v-select dense outlined
                    label="Modèle *"
                    :items="models"
                    v-model="form.recharge_card_id"
                    :error-messages="errors.recharge_card_id"
                    item-value="id"
                    item-text="name"></v-select>

          <v-file-input dense
                        hide-details="auto"
                        :error-messages="errors.excel_file"
                        @change="uploadFile"
                        ref="file"
                        outlined
                        accept=".xlsx,.xls,.csv"
                        label="Fichier excel *"></v-file-input>

        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary"
                 :loading="isLoading"
                 depressed
                 class="ml-2"
                 @click="handleImport">
            <v-icon left>mdi-import</v-icon>
            Importer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      dialog: false,
      isLoading: false,
      models: [],
      errors: {},
      form: {
        recharge_card_id: '',
        excel_file: ''
      },
    }
  },
  methods: {
    uploadFile(file) {
      this.form.excel_file = file
    },
    open() {
      this.dialog = true
      this.errors = {}
      this.form = {
        recharge_card_id: '',
        excel_file: ''
      }
      this.getModels()

      if (this.$refs.file) {
        this.$refs.file.reset()
      }
    },
    handleImport() {
      this.isLoading = true
      this.errors = {}

      let formData = new FormData()
      formData.append('recharge_card_id', this.form.recharge_card_id)
      formData.append('excel_file', this.form.excel_file)

      HTTP.post('/cards/import', formData).then(() => {
        this.isLoading = false
        this.$emit('refresh')
        this.$successMessage = "Opération réussi !"
        this.dialog = false
      }).catch(err => {
        this.errors = err.response.data.errors
        this.isLoading = false
        console.log(err)
      })
    },
    getModels() {
      this.models = this.$store.getters.getCards;
    },
  }
}
</script>

<style scoped>

</style>