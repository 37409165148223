<template>
  <div>
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card class="text-center">
        <v-card-title>
          <v-icon left>mdi-card-bulleted-outline</v-icon>
          Information Martchip Card
          <v-spacer />

          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-6">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>{{ card.id }}</td>
                </tr>
                <tr>
                  <td>Martship ID</td>
                  <td>{{ card.martship_id }}</td>
                </tr>

                <tr>
                  <td>Status</td>
                  <td>
                    <v-chip
                      :color="setStatusColor(card.martship_state)"
                      label
                      small
                      dark
                    >
                      {{ card.martship_state }}
                    </v-chip>
                  </td>
                </tr>

                <tr>
                  <td>Mode</td>
                  <td>{{ card.mode }}</td>
                </tr>

                <tr>
                  <td>Reponse</td>
                  <td>{{ card.martship_response }}</td>
                </tr>


              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      dialog: false,
      card: null,
    };
  },
  methods: {
    open(card) {
      this.card = card;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },

    setStatusColor(status) {
      
      switch (status) {
        case "OK":
          return "success";
        case "PENDING":
          return "blue ";
        case "REFUNDED":
          return "error ";
      }
    },
  },
};
</script>